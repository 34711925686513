var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"p-3"},[(_vm.practices)?_c('div',[_c('Heading',{staticClass:"mb-3",attrs:{"content":"Explanation"}}),_c('div',[(_vm.practices && _vm.practices.length > 0)?_c('div',{staticClass:"question-title"},[_c('el-collapse',{attrs:{"accordion":""},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.practices),function(practice,practiceIndex){return _c('el-collapse-item',{key:practiceIndex,attrs:{"name":practiceIndex + 1}},[_c('template',{slot:"title"},[(_vm.practiceType === 'composePractices')?_c('h6',{staticClass:"m-0"},[_vm._v(" SAT Module "+_vm._s(practiceIndex + 1)+" "),(practice.subject && practice.subject.name)?_c('span',[_vm._v(" - "+_vm._s(_vm.titleCase(practice.subject.name))+" ")]):_vm._e()]):_vm._e(),(_vm.practiceType === 'singlePractice')?_c('h6',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.getCompanyExamTitle(practice.exam.title))+" ")]):_vm._e()]),_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"showTags"},[_c('div',{staticClass:"showTags-item"},[_c('span',{staticClass:"view_question  correct-0"}),_c('b',{staticStyle:{"line-height":"1.8rem"}},[_vm._v(" 0~24%")])]),_c('div',{staticClass:"showTags-item"},[_c('span',{staticClass:"view_question  correct-25"}),_c('b',{staticStyle:{"line-height":"1.8rem"}},[_vm._v(" 25~49% ")])]),_c('div',{staticClass:"showTags-item"},[_c('span',{staticClass:"view_question  correct-50"}),_c('b',{staticStyle:{"line-height":"1.8rem"}},[_vm._v(" 50~74%")])]),_c('div',{staticClass:"showTags-item"},[_c('span',{staticClass:"view_question  correct-75"}),_c('b',{staticStyle:{"line-height":"1.8rem"}},[_vm._v(" 75~100%")])])]),_vm._l((practice.exam.exam_questions),function(title,index){return _c('span',{key:index},[_c('el-tooltip',{attrs:{"placement":"top"}},[_c('div',{attrs:{"slot":"content"},domProps:{"innerHTML":_vm._s(
                        ("<b>" + (_vm.getPer(
                          title.correct / (title.correct + title.wrong)
                        )) + "% </b> <br><br>" + (_vm.showWrongAns(
                          title.question.sat_answers[0].answers[0],
                          title.student_answers
                        )))
                      )},slot:"content"}),_c('div',{class:("view_question " + (_vm.showAnswerCorrectPer(
                          title.correct,
                          title.correct + title.wrong
                        ))),on:{"click":function($event){return _vm.viewBrowse(
                          title.question_id,
                          index + 1,
                          practiceIndex + 1
                        )}}},[(title.question_id == _vm.questionId)?_c('span',{staticClass:"currentIcon questionsOrderActive"},[_c('i',{staticClass:"fa-solid fa-location-dot"})]):_vm._e(),_vm._v(" "+_vm._s(index + 1)+" ")])])],1)})],2)],2)}),1)],1):_vm._e()]),_c('div',{staticClass:"mt-3 text-center",staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.cannotBack),expression:"!cannotBack"}],attrs:{"type":"success"},on:{"click":_vm.questionBack}},[_c('b',[_c('i',{staticClass:"el-icon-back"}),_vm._v(" Back ")])]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.cannotNext),expression:"!cannotNext"}],attrs:{"type":"success"},on:{"click":_vm.questionNext}},[_c('b',[_vm._v(" Next "),_c('i',{staticClass:"el-icon-right"})])])],1),_c('el-card',{staticClass:"mt-3"},[(_vm.isAdmin)?[_c('div',{staticClass:"text-right ml-2 mr-2"},[_c('router-link',{attrs:{"to":{
                name: 'SatEditQuestion',
                query: {
                  questionId: _vm.question.id
                }
              }}},[_c('el-button',{attrs:{"type":"primary","size":"medium"}},[_c('i',{staticClass:"fa fa-edit"}),_vm._v(" Edit ")])],1)],1)]:_vm._e(),(_vm.isPhone)?_c('div',{staticClass:"test-paper"},[(
              _vm.question &&
                _vm.question.type === 'default' &&
                _vm.question.sat_passage_id > 0 &&
                _vm.question.sat_passage.content
            )?_c('MultipleChoiceWithPassage_M',{attrs:{"mode":"explanation","passageIntro":_vm.question.sat_passage.introduction
                ? _vm.question.sat_passage.introduction.intro
                : _vm.nullF,"passageContent":_vm.getPassage,"questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":_vm.answer ? _vm.answer.answers[0] : '/',"correctAnswer":_vm.question.sat_answers[0].answers[0],"isCorrect":_vm.answer ? _vm.answer.is_correct == 1 : false,"explanation":_vm.question.explanation,"showMark":false,"showLine":_vm.question.sat_sat_subject.sat_subject.name === 'reading',"activities":_vm.activities}}):(
              _vm.question &&
                _vm.question.type === 'default' &&
                (_vm.question.sat_passage === null ||
                  _vm.question.sat_passage.content === null)
            )?_c('MultipleChoice_M',{attrs:{"mode":"explanation","questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":_vm.answer ? _vm.answer.answers[0] : '/',"correctAnswer":_vm.question.sat_answers[0].answers[0],"isCorrect":_vm.answer ? _vm.answer.is_correct == 1 : false,"explanation":_vm.question.explanation,"showMark":false,"activities":_vm.activities}}):(_vm.question.type === 'math')?_c('Math_M',{attrs:{"mode":"explanation","questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":_vm.answer ? _vm.answer.answers[0] : '/',"correctAnswer":_vm.question.sat_answers[0].answers[0],"isCorrect":_vm.answer ? _vm.answer.is_correct == 1 : false,"explanation":_vm.question.explanation,"showMark":false,"activities":_vm.activities}}):_c('MultipleChoice_M',{attrs:{"mode":"explanation","questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":_vm.answer ? _vm.answer.answers[0] : '/',"correctAnswer":_vm.question.sat_answers[0].answers[0],"isCorrect":_vm.answer ? _vm.answer.is_correct == 1 : false,"explanation":_vm.question.explanation,"showMark":false,"activities":_vm.activities}})],1):_c('div',{staticClass:"test-paper"},[(
              _vm.question &&
                _vm.question.type === 'default' &&
                _vm.question.sat_passage_id > 0 &&
                _vm.question.sat_passage.content
            )?_c('MultipleChoiceWithPassage',{attrs:{"mode":"explanation","passageIntro":_vm.question.sat_passage.introduction
                ? _vm.question.sat_passage.introduction.intro
                : null,"passageContent":_vm.getPassage,"questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":_vm.answer ? _vm.answer.answers[0] : '/',"correctAnswer":_vm.question.sat_answers[0].answers[0],"isCorrect":_vm.answer ? _vm.answer.is_correct == 1 : false,"explanation":_vm.question.explanation,"showMark":false,"showLine":_vm.question.sat_sat_subject.sat_subject.name === 'reading',"activities":_vm.answer && _vm.answer.other_status
                ? _vm.answer.other_status.activities
                : null,"studentAnswers":_vm.activeQuestion ? _vm.activeQuestion.student_answers : null}}):(
              _vm.question &&
                _vm.question.type === 'default' &&
                (_vm.question.sat_passage === null ||
                  _vm.question.sat_passage.content === null)
            )?_c('MultipleChoice',{attrs:{"mode":"explanation","questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":_vm.answer ? _vm.answer.answers[0] : '/',"correctAnswer":_vm.question.sat_answers[0].answers[0],"isCorrect":_vm.answer ? _vm.answer.is_correct == 1 : false,"explanation":_vm.question.explanation,"showMark":false,"activities":_vm.answer && _vm.answer.other_status
                ? _vm.answer.other_status.activities
                : null,"studentAnswers":_vm.activeQuestion ? _vm.activeQuestion.student_answers : null}}):(_vm.question && _vm.question.type === 'math')?_c('Math',{attrs:{"mode":"explanation","questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":_vm.answer !== null ? _vm.answer.answers[0] : '/',"correctAnswer":_vm.question.sat_answers[0].answers[0],"isCorrect":_vm.answer ? _vm.answer.is_correct == 1 : false,"explanation":_vm.question.explanation,"showMark":false,"activities":_vm.answer && _vm.answer.other_status
                ? _vm.answer.other_status.activities
                : null,"studentAnswers":_vm.activeQuestion ? _vm.activeQuestion.student_answers : null}}):_vm._e()],1)],2)],1):_c('div',{staticClass:"text-center"},[_c('el-alert',{attrs:{"title":"Loading...","type":"info","show-icon":""}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }