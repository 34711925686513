<template>
  <div>
    <div class="p-3" >
      <div v-if="practices">
        <Heading class="mb-3" content="Explanation"></Heading>
        <div>
          <div class="question-title" v-if="practices && practices.length > 0">
            <el-collapse v-model="activeName" accordion>
              <el-collapse-item
                :name="practiceIndex + 1"
                v-for="(practice, practiceIndex) in practices"
                :key="practiceIndex"
              >
                <template slot="title">
                  <h6 class="m-0" v-if="practiceType === 'composePractices'">
                    SAT Module {{ practiceIndex + 1 }}
                    <span v-if="practice.subject && practice.subject.name">
                      - {{ titleCase(practice.subject.name) }}
                    </span>
                  </h6>
                  <h6 class="m-0" v-if="practiceType === 'singlePractice'">
                    {{ getCompanyExamTitle(practice.exam.title) }}
                  </h6>
                </template>
                <div class="p-3">
                  <div class="showTags">
                    <div class="showTags-item">
                      <span class="view_question  correct-0" />
                      <b style="line-height:1.8rem"> 0~24%</b>
                    </div>
                    <div class="showTags-item">
                      <span class="view_question  correct-25" />
                      <b style="line-height:1.8rem"> 25~49% </b>
                    </div>
                    <div class="showTags-item">
                      <span class="view_question  correct-50" />
                      <b style="line-height:1.8rem"> 50~74%</b>
                    </div>
                    <div class="showTags-item">
                      <span class="view_question  correct-75" />
                      <b style="line-height:1.8rem"> 75~100%</b>
                    </div>
                    <!-- <div class="showTags-item">
                      <span class="view_question  correct-100" />
                      <b style="line-height:1.8rem">100%</b>
                    </div> -->
                  </div>
                  <span
                    v-for="(title, index) in practice.exam.exam_questions"
                    :key="index"
                  >
                    <el-tooltip placement="top">
                      <div
                        slot="content"
                        v-html="
                          `<b>${getPer(
                            title.correct / (title.correct + title.wrong)
                          )}% </b> <br><br>${showWrongAns(
                            title.question.sat_answers[0].answers[0],
                            title.student_answers
                          )}`
                        "
                      ></div>
                      <div
                        @click="
                          viewBrowse(
                            title.question_id,
                            index + 1,
                            practiceIndex + 1
                          )
                        "
                        :class="
                          `view_question ${showAnswerCorrectPer(
                            title.correct,
                            title.correct + title.wrong
                          )}`
                        "
                      >
                        <span
                          v-if="title.question_id == questionId"
                          class="currentIcon questionsOrderActive"
                        >
                          <i class="fa-solid fa-location-dot" />
                        </span>
                        {{ index + 1 }}
                      </div>
                    </el-tooltip>
                  </span>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
        <div
          class="mt-3 text-center"
          style="display: flex;justify-content:space-around;"
        >
          <el-button type="success" v-show="!cannotBack" @click="questionBack">
            <b>
              <i class="el-icon-back"></i>
              Back
            </b>
          </el-button>
          <el-button type="success" v-show="!cannotNext" @click="questionNext">
            <b>
              Next
              <i class="el-icon-right"></i>
            </b>
          </el-button>
        </div>
        <el-card class="mt-3">
          <template v-if="isAdmin">
            <div class="text-right ml-2 mr-2">
              <router-link
                :to="{
                  name: 'SatEditQuestion',
                  query: {
                    questionId: question.id
                  }
                }"
              >
                <el-button type="primary" size="medium">
                  <i class="fa fa-edit"></i>
                  Edit
                </el-button>
              </router-link>
            </div>
          </template>
          <div class="test-paper" v-if="isPhone">
            <MultipleChoiceWithPassage_M
              v-if="
                question &&
                  question.type === 'default' &&
                  question.sat_passage_id > 0 &&
                  question.sat_passage.content
              "
              mode="explanation"
              :passageIntro="
                question.sat_passage.introduction
                  ? question.sat_passage.introduction.intro
                  : nullF
              "
              :passageContent="getPassage"
              :questionOrder="questionOrder"
              :content="question.content"
              :options="question.sat_options"
              :answer="answer ? answer.answers[0] : '/'"
              :correctAnswer="question.sat_answers[0].answers[0]"
              :isCorrect="answer ? answer.is_correct == 1 : false"
              :explanation="question.explanation"
              :showMark="false"
              :showLine="question.sat_sat_subject.sat_subject.name === 'reading'"
              :activities="activities"
            ></MultipleChoiceWithPassage_M>
            <MultipleChoice_M
              v-else-if="
                question &&
                  question.type === 'default' &&
                  (question.sat_passage === null ||
                    question.sat_passage.content === null)
              "
              mode="explanation"
              :questionOrder="questionOrder"
              :content="question.content"
              :options="question.sat_options"
              :answer="answer ? answer.answers[0] : '/'"
              :correctAnswer="question.sat_answers[0].answers[0]"
              :isCorrect="answer ? answer.is_correct == 1 : false"
              :explanation="question.explanation"
              :showMark="false"
              :activities="activities"
            ></MultipleChoice_M>
            <Math_M
              v-else-if="question.type === 'math'"
              mode="explanation"
              :questionOrder="questionOrder"
              :content="question.content"
              :options="question.sat_options"
              :answer="answer ? answer.answers[0] : '/'"
              :correctAnswer="question.sat_answers[0].answers[0]"
              :isCorrect="answer ? answer.is_correct == 1 : false"
              :explanation="question.explanation"
              :showMark="false"
              :activities="activities"
            ></Math_M>
            <MultipleChoice_M
              v-else
              mode="explanation"
              :questionOrder="questionOrder"
              :content="question.content"
              :options="question.sat_options"
              :answer="answer ? answer.answers[0] : '/'"
              :correctAnswer="question.sat_answers[0].answers[0]"
              :isCorrect="answer ? answer.is_correct == 1 : false"
              :explanation="question.explanation"
              :showMark="false"
              :activities="activities"
            ></MultipleChoice_M>
          </div>
          <div class="test-paper" v-else>
            <MultipleChoiceWithPassage
              v-if="
                question &&
                  question.type === 'default' &&
                  question.sat_passage_id > 0 &&
                  question.sat_passage.content
              "
              mode="explanation"
              :passageIntro="
                question.sat_passage.introduction
                  ? question.sat_passage.introduction.intro
                  : null
              "
              :passageContent="getPassage"
              :questionOrder="questionOrder"
              :content="question.content"
              :options="question.sat_options"
              :answer="answer ? answer.answers[0] : '/'"
              :correctAnswer="question.sat_answers[0].answers[0]"
              :isCorrect="answer ? answer.is_correct == 1 : false"
              :explanation="question.explanation"
              :showMark="false"
              :showLine="question.sat_sat_subject.sat_subject.name === 'reading'"
              :activities="
                answer && answer.other_status
                  ? answer.other_status.activities
                  : null
              "
              :studentAnswers="
                activeQuestion ? activeQuestion.student_answers : null
              "
            ></MultipleChoiceWithPassage>
            <MultipleChoice
              v-else-if="
                question &&
                  question.type === 'default' &&
                  (question.sat_passage === null ||
                    question.sat_passage.content === null)
              "
              mode="explanation"
              :questionOrder="questionOrder"
              :content="question.content"
              :options="question.sat_options"
              :answer="answer ? answer.answers[0] : '/'"
              :correctAnswer="question.sat_answers[0].answers[0]"
              :isCorrect="answer ? answer.is_correct == 1 : false"
              :explanation="question.explanation"
              :showMark="false"
              :activities="
                answer && answer.other_status
                  ? answer.other_status.activities
                  : null
              "
              :studentAnswers="
                activeQuestion ? activeQuestion.student_answers : null
              "
            ></MultipleChoice>
            <Math
              v-else-if="question && question.type === 'math'"
              mode="explanation"
              :questionOrder="questionOrder"
              :content="question.content"
              :options="question.sat_options"
              :answer="answer !== null ? answer.answers[0] : '/'"
              :correctAnswer="question.sat_answers[0].answers[0]"
              :isCorrect="answer ? answer.is_correct == 1 : false"
              :explanation="question.explanation"
              :showMark="false"
              :activities="
                answer && answer.other_status
                  ? answer.other_status.activities
                  : null
              "
              :studentAnswers="
                activeQuestion ? activeQuestion.student_answers : null
              "
            ></Math>
          </div>
        </el-card>
      </div>
      <div v-else class="text-center">
        <el-alert
          title="Loading..."
          type="info"
          show-icon
        >
        </el-alert>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import $ from "jquery";
import _ from "lodash";
import Breadcrumb from "@/components/Breadcrumb";
import SAT from "@/apis/sat";
import Common from "@/mixins/common.js";
import role from "@/mixins/role.js";

import { instant, user } from "@ivy-way/material";

export default {
  metaInfo() {
    return {
      title: "Explanation - " + this.CompanyName
    };
  },

  components: {},

  mixins: [Common, role],

  props: [],
  data() {
    return {
      questionId: null,
      exam: null,
      practices: null,
      result: null,
      answer: null,
      question: null,
      activeName: null,
      questionOrder: null,
      annotation: [],
      studentsResults: [],
      showAnswer: false
    };
  },
  computed: {
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    isTeacher() {
      let isTeacher = this.isRoleTeacher();
      return isTeacher;
    },
    cannotBack() {
      return this.activeName == 1 && this.questionOrder == 1;
    },
    cannotNext() {
      return (
        this.activeName == this.practices.length &&
        this.questionOrder ==
          this.practices[parseInt(this.activeName) - 1].exam.exam_questions
            .length
      );
    },
    activeQuestion() {
      let question = this.practices[parseInt(this.activeName) - 1].exam
        .exam_questions[this.questionOrder - 1];
      for (let key in question.student_answers) {
        question.student_answers[key] = _.sortBy(
          question.student_answers[key],
          ["user.first_name"]
        );
      }
      return question;
    },
    user() {
      return user;
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    user_exam_id() {
      if (this.$route.query.user_exam_id) {
        console.log(this.$route.query.user_exam_id);
        return this.$route.query.user_exam_id.split(",");
      } else {
        return [];
      }
    },
    practiceType() {
      return this.$route.query.practiceType;
    },
    user_exam_answer_id() {
      return this.$route.query.user_exam_answer_id;
    },
    getPassage() {
      let passage = "";
      if (
        this.answer &&
        this.answer.other_status &&
        this.answer.other_status.annotation &&
        this.answer.other_status.annotation.passage
      ) {
        passage = this.answer.other_status.annotation.passage;
      } else {
        if (this.question.sat_passage) {
          let p1 = this.question.sat_passage.content;
          let p2 = this.question.sat_passage.introduction?.below_content;
          if (p2) {
            passage = `<h5 style="padding-left:40px">Passage I</h5>${p1} <br /><h5 style="padding-left:40px">Passage II</h5>${p2}`;
          } else {
            passage = p1;
          }
        }
      }
      // passage = this.getLine(passage);
      return passage;
    }
  },
  watch: {
    async questionId() {
      this.question = null;
      const res = await SAT.getExplanation(this.questionId);
      this.question = res.sat_question;
      if (!this.question.sat_sat_subject) {
        this.question.sat_sat_subject = {
          sat_subject: {
            id: 5,
            name: "english"
          }
        };
      }
    }
  },

  async mounted() {
    if (this.user_exam_id) {
      if (this.practiceType === "composePractices") {
        const res = await Promise.all(
          this.user_exam_id.map(id => SAT.getComposePracticeResult(id))
        );
        let studentsResults = [];
        res.forEach(r => {
          studentsResults.push(r.result);
        });
        this.practices = this.getPractices(
          res[0].compose_practice.practices,
          studentsResults
        );
      } else if (this.practiceType === "singlePractice") {
        const res = await Promise.all(
          this.user_exam_id.map(id => SAT.getPracticeResult(id))
        );
        let studentsResults = [];
        res.forEach(r => {
          studentsResults.push(r.result);
        });
        this.practices = this.getPractices([res[0].practice], studentsResults);
      }
    }
    this.activeName = this.$route.query.passageIndex
      ? parseInt(this.$route.query.passageIndex)
      : "1";
    this.questionOrder = this.$route.query.order
      ? this.$route.query.order
      : "1";
    this.questionId = this.$route.params.questionId;
  },

  methods: {
    questionBack() {
      if (this.questionOrder == 1) {
        if (parseInt(this.activeName) > 1) {
          this.activeName--;
          this.questionOrder = this.practices[
            parseInt(this.activeName) - 1
          ].exam.exam_questions.length;
        }
      } else {
        this.questionOrder--;
      }
      this.questionId = this.practices[
        parseInt(this.activeName) - 1
      ].exam.exam_questions[this.questionOrder - 1].question_id;
    },
    questionNext() {
      if (
        this.questionOrder ==
        this.practices[parseInt(this.activeName) - 1].exam.exam_questions.length
      ) {
        if (parseInt(this.activeName) < this.practices.length) {
          this.activeName++;
          this.questionOrder = 1;
        }
      } else {
        this.questionOrder++;
      }
      this.questionId = this.practices[
        parseInt(this.activeName) - 1
      ].exam.exam_questions[this.questionOrder - 1].question_id;
    },
    viewBrowse(questionId, index, practiceIndex) {
      console.log(questionId, practiceIndex, index);
      this.questionId = questionId;
      this.activeName = practiceIndex;
      this.questionOrder = index;
    },
    getPractices(practices, studentsResults) {
      if (practices && practices.length > 0) {
        practices.forEach(async practice => {
          practice.exam.exam_questions.forEach(question => {
            question["student_answers"] = {};
            question["correct"] = 0;
            question["wrong"] = 0;
            studentsResults.forEach(async student => {
              student["hasAnswer"] = 0;
              student.user_exam_answers.forEach(async studentAnswer => {
                if (
                  studentAnswer.exam_question.question_id ===
                  question.question_id
                ) {
                  if (studentAnswer.answers[0]) {
                    student["hasAnswer"] = 1;
                    if (studentAnswer.is_correct) {
                      question["correct"]++;
                    } else {
                      question["wrong"]++;
                    }
                    if (
                      question["student_answers"][`${studentAnswer.answers[0]}`]
                    ) {
                      question["student_answers"][
                        `${studentAnswer.answers[0]}`
                      ].push({ user: student.user });
                    } else {
                      question["student_answers"][
                        `${studentAnswer.answers[0]}`
                      ] = [{ user: student.user }];
                    }
                  } else {
                    if (question["student_answers"]["*"]) {
                      question["student_answers"]["*"].push({
                        user: student.user
                      });
                    } else {
                      question["student_answers"]["*"] = [
                        { user: student.user }
                      ];
                    }
                  }
                }
              });
            });
            studentsResults.forEach(student => {
              if (student["hasAnswer"] === 0) {
                if (question["student_answers"]["*"]) {
                  question["student_answers"]["*"].push({ user: student.user });
                } else {
                  question["student_answers"]["*"] = [{ user: student.user }];
                }
              }
            });
          });
        });
        this.showAnswer = true;
      }
      return practices;
    },
    getPer(val) {
      let str = 0;
      if (val > 0) {
        str = Math.round(val * 100);
      }
      return str;
    },
    showWrongAns(cAns, studentsAnswer) {
      let text = "None";
      let ctext = "";
      let wtext = "";
      let hasCorrect = false;
      let hasWrong = false;
      let i = 0;
      for (let key in studentsAnswer) {
        let name = "";
        let nameArr = [];
        studentsAnswer[key].forEach((student, index) => {
          let displayName = student.user.first_name;
          nameArr.push(displayName);
        });

        nameArr = _.sortBy(nameArr);
        name = nameArr.join(", ");
        if (key == cAns) {
          hasCorrect = true;
          ctext += `Correct <br /><b class="text-success">${cAns} (${name})</b>`;
        } else if (key != cAns && key != "X") {
          hasWrong = true;
          if (i === 0) {
            wtext += `Wrong <br /><b class="text-danger">${key} (${name})</b>`;
          } else {
            wtext += ` <br /><b class="text-danger">${key} (${name})</b>`;
          }
          i++;
        }
      }
      if (hasCorrect && hasWrong) {
        text = ctext + "<br /><br />" + wtext;
      }
      if (!hasCorrect && hasWrong) {
        text = wtext;
      }
      if (hasCorrect && !hasWrong) {
        text = ctext;
      }
      return text;
    },
    setHighlight() {
      this.$nextTick(() => {
        let vm = this;
        $("span[data-id]").each(function() {
          if ($(this).data("id") !== "") {
            $(this).addClass("highlight");
          }
        });
        $("span[data-id]").each(function() {
          if ($(this).data("id")) {
            $(this).addClass("highlight");
          }
        });
        $("span[data-id]").mouseover(function() {
          let annotationArr = [];
          if ($(this).data("id")) {
            annotationArr = $(this).data("id");
            let item = annotationArr[annotationArr.length - 1];
            $(".tooltipText").remove();
            $(this).append(
              `<span class="tooltipText">${vm.annotation[item].annotation}</span>`
            );
            $("span[data-id]").each(function() {
              if (
                $(this).data("id") &&
                $(this)
                  .data("id")
                  .indexOf(item) > -1
              ) {
                $(this).addClass("active");
              }
            });
          }
        });
        $("span[data-id]").mouseout(function() {
          $("span[data-id]").removeClass("active");
          $(".tooltipText").remove();
        });
      });
    },
    showAnswerCorrectPer(correct, total) {
      let per = 0;
      per = this.getPer(correct / total);
      if (parseFloat(per) < 25) {
        return "correct-0";
      } else if (parseFloat(per) >= 25 && parseInt(per) < 50) {
        return "correct-25";
      } else if (parseFloat(per) >= 50 && parseInt(per) < 75) {
        return "correct-50";
      } else if (parseFloat(per) >= 75 && parseInt(per) < 100) {
        return "correct-75";
      } else if (parseInt(per) === 100) {
        return "correct-100";
      }
    }
  }
};
</script>

<style scoped>
::v-deep .el-collapse-item:nth-child(odd) .el-collapse-item__header {
  background-color: #fafafa;
}
.question-title {
  margin: 0 -1rem;
}
::v-deep .el-collapse-item .el-collapse-item__header {
  padding: 0 1rem;
  color: var(--themeColor);
}
::v-deep .el-collapse-item .el-collapse-item__header:hover {
  background-color: var(--themeColor);
  color: white !important;
}
::v-deep .el-collapse-item__content {
  padding: 0;
}
.view_question {
  position: relative;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  line-height: 1.8rem;
  text-align: center;
  font-weight: 700;
  font-size: 1rem;
  border-radius: 5px;
  margin: 1rem 5px 1rem;
  text-decoration: none;
  cursor: pointer;
  background-color: var(--themeColor);
  color: white !important;
}
::v-deep .correct-100 {
  background-color: #42a16ae6 !important;
  color: white;
}
::v-deep .correct-75 {
  background-color: #42a16ae6 !important;
  color: white;
}
::v-deep .correct-50 {
  background-color: #7cc89ccf !important;
  color: white;
}
::v-deep .correct-25 {
  background-color: #fad5d5 !important;
  color: white;
}
::v-deep .correct-0 {
  background-color: #ffa3a3 !important;
  color: white;
}
.wrong {
  border-color: #ffdadd;
  background-color: #ffdadd;
}
.view_question.empty {
  background-color: #ccc;
  border-color: #ccc;
}
.showTags {
  display: flex;
  /* justify-content: center; */
}
.showTags-item {
  margin: 0 10px 20px 0;
  display: flex;
}
.showTags-item .view_question {
  height: 2rem;
  width: 2rem;
  line-height: 1.8rem;
  margin: 0 5px;
}
.questionsOrderActive {
  position: absolute;
  display: inline-block;
  width: 1.8rem;
  left: 0;
  text-align: center;
  top: -1.7rem;
  color: var(--themeColor);
}
::v-deep .MathJax .mrow {
  font-size: 1.2rem !important;
}
.test-paper {
  min-height: 600px;
}
</style>
